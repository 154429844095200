import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { WebsiteBuilderModel } from '@/models/WebsiteBuilder';
import { isDevelopment } from '@/utility/generic';

const ViewWebsitePage = () => {
  const { t } = useTranslation();

  const { watch } = useFormContext<WebsiteBuilderModel>();
  const formRoute = watch('route');

  const url = useMemo(
    () =>
      isDevelopment
        ? `https://test-fans.unhurdmusic.com/artist/${formRoute}`
        : `https://fans.unhurdmusic.com/artist/${formRoute}`,
    [formRoute]
  );

  return (
    <>
      <h3>{t('WEBSITE-BUILDER.VIEW-YOUR-WEBSITE')}</h3>
      <p className="text-faded mb48 mt10">{t('WEBSITE-BUILDER.VIEW-YOUR-WEBSITE-DESCRIPTION')}</p>
      <Button
        className="btn-blue"
        onClick={() => {
          window.open(url, '_blank');
        }}
      >
        View your website 🎉
      </Button>
    </>
  );
};

export default ViewWebsitePage;
