import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useValidateUrlRoute from '@/hooks/website-builder/useValidateUrlRoute';
import { WebsiteBuilderModel } from '@/models/WebsiteBuilder';
import { isDevelopment } from '@/utility/generic';

const ChooseURLPath = ({ isEditMode }: { isEditMode: boolean }) => {
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext<WebsiteBuilderModel>();
  const formRoute = watch('route');

  const { isValidUrlRoute, validateUrlRouteIsLoading } = useValidateUrlRoute({ route: formRoute });

  return (
    <>
      <h3>{t('WEBSITE-BUILDER.CHOOSE-YOUR-URL')}</h3>
      <p className="text-faded mb48 mb20-lg-down mt10">{t('WEBSITE-BUILDER.CHOOSE-YOUR-URL-DESCRIPTION')}</p>
      <div className="card mb20 m-auto mb300">
        <div className="d-flex gap20">
          <p className="mt12 w40p">{`${isDevelopment ? 'test-' : ''}fans.unhurdmusic.com/artist/`}</p>
          <div className="w100p">
            <input
              className={!validateUrlRouteIsLoading && !isValidUrlRoute && !isEditMode ? 'error-outline' : ''}
              placeholder={t('WEBSITE-BUILDER.META-PIXEL-CODE')}
              disabled={isEditMode}
              value={formRoute ?? ''}
              onChange={(event) => setValue('route', event.target.value)}
            />
            <p className="small text-faded text-left mt4">
              Your URL can only contain letters and the following characters: + _ -
            </p>
            {!validateUrlRouteIsLoading && !isValidUrlRoute && !isEditMode && (
              <p className="small text-error text-left mt8">This path is already in use</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseURLPath;
