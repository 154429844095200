import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Tab, Tabs } from '@mui/material';

import { DesktopScreen } from '@/components/utility/screen-prototypes/DesktopScreen';
import { MobileScreen } from '@/components/utility/screen-prototypes/MobileScreen';
import { TabletScreen } from '@/components/utility/screen-prototypes/TabletScreen';
import { WebsiteBuilderBackgrounds } from '@/constants/WebsiteBuilderBackgrounds';
import useArtist from '@/hooks/artist/useArtist';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import {
  WebsiteBuilderBackgroundColorModel,
  WebsiteBuilderBackgroundImageModel,
  WebsiteBuilderModel,
} from '@/models/WebsiteBuilder';

import FanHubPreviewContents from './FanHubPreviewContents';

export type ExtendedCSSProperties = React.CSSProperties & {
  '--card-background-color'?: string;
  '--text-color'?: string;
};

const WebsitePreviewPage = () => {
  const { t } = useTranslation();
  const { breakpointHitCustom } = useBreakpoints(680);
  const { artist } = useArtist();

  const [tabValue, setTabValue] = useState<string>('desktop');

  const [chosenBackground, setChosenBackground] = useState<
    WebsiteBuilderBackgroundImageModel | WebsiteBuilderBackgroundColorModel
  >();
  const [style, setStyle] = useState<ExtendedCSSProperties>();

  const { watch } = useFormContext<WebsiteBuilderModel>();
  const formThemeBackground = watch('theme.background');
  const formThemeBackgroundName = watch('theme.background.name');

  const formArtistName = watch('artistName');
  const formPrimaryLinks = watch('primaryLinks');
  const formBio = watch('bio');

  const isImageModel = (
    background: WebsiteBuilderBackgroundImageModel | WebsiteBuilderBackgroundColorModel
  ): background is WebsiteBuilderBackgroundImageModel => 'url' in background;

  useEffect(() => {
    if (chosenBackground) {
      const style = {
        backgroundImage: isImageModel(chosenBackground) ? `url(${chosenBackground.url})` : undefined,
        backgroundColor: !isImageModel(chosenBackground) ? chosenBackground.hex : undefined,
        backgroundSize: 'cover',
      };
      setStyle(style);
    }
  }, [chosenBackground]);

  useEffect(() => {
    if (!breakpointHitCustom) {
      setTabValue('all');
    } else {
      setTabValue('desktop');
    }
  }, [breakpointHitCustom]);

  useEffect(() => {
    if (formThemeBackground || formThemeBackgroundName) {
      const { images, colors } = WebsiteBuilderBackgrounds;
      const allBackgrounds = [...images, ...colors];
      const matchedBackground = allBackgrounds.find(
        (item) => item.name === formThemeBackground || item.name === formThemeBackgroundName
      );
      if (matchedBackground) {
        setChosenBackground(matchedBackground);
      }
    }
  }, [formThemeBackground, formThemeBackgroundName]);

  const contents = (
    <FanHubPreviewContents
      artistName={formArtistName}
      image={artist?.details.profileImageUrl || ''}
      primaryLinks={formPrimaryLinks}
      bio={formBio}
      chosenBackground={chosenBackground}
    ></FanHubPreviewContents>
  );

  return (
    <>
      <h3>{t('WEBSITE-BUILDER.PREVIEW')}</h3>
      <p className="text-faded mb48 mb20-lg-down mt10">{t('WEBSITE-BUILDER.PREVIEW-DESCRIPTION')}</p>

      <Box>
        <Tabs
          sx={{ justifyContent: 'center' }}
          value={tabValue}
          onChange={(_, value) => {
            setTabValue(value);
          }}
          className="tabs-center"
        >
          {!breakpointHitCustom && <Tab label="All" value="all" />}
          <Tab label="Desktop" value="desktop" />
          <Tab label="Mobile" value="mobile" />
          <Tab label="Tablet" value="tablet" />
        </Tabs>
      </Box>
      <div className="br24 p20" style={style}>
        {tabValue === 'all' && (
          <div className="pos-rel d-flex preview-group-container">
            <div className="mobile-group">
              <MobileScreen>{contents}</MobileScreen>
            </div>
            <div className="desktop-group">
              <DesktopScreen>{contents}</DesktopScreen>
            </div>
            <div className="tablet-group">
              <TabletScreen>{contents}</TabletScreen>
            </div>
          </div>
        )}
        {tabValue === 'desktop' && <DesktopScreen>{contents}</DesktopScreen>}
        {tabValue === 'mobile' && <MobileScreen>{contents}</MobileScreen>}
        {tabValue === 'tablet' && <TabletScreen>{contents}</TabletScreen>}
      </div>
    </>
  );
};

export default WebsitePreviewPage;
