import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Tooltip } from '@mui/material';
import { format, parseISO } from 'date-fns';

import ButtonComponent from '@/components/utility/microcomponents/Button';
import Card from '@/components/utility/microcomponents/Card';
import CustomIcons from '@/components/utility/microcomponents/CustomIcons';
import AddTourDateModal from '@/components/utility/modals/AddTourDateModal';
import DialogModal from '@/components/utility/modals/DialogModal';
import { WebsiteBuilderModel, WebsiteBuilderTourDateModel } from '@/models/WebsiteBuilder';

const TourDatesPage = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [chosenTourDate, setChosenTourDate] = useState<WebsiteBuilderTourDateModel>();
  const [dateToDelete, setDateToDelete] = useState<WebsiteBuilderTourDateModel>();

  const { setValue, watch } = useFormContext<WebsiteBuilderModel>();

  const tourDates = watch('tourDates');

  const addTourDate = (value: WebsiteBuilderTourDateModel | boolean) => {
    if (value && typeof value === 'object') {
      if (chosenTourDate) {
        const newTourDates = tourDates.map((item) => (item === chosenTourDate ? value : item));
        setValue('tourDates', newTourDates);
      } else {
        const newTourDates = [...tourDates, value];
        setValue('tourDates', newTourDates);
      }
    }
    setChosenTourDate(undefined);
    setOpen(false);
  };

  const removeTourDate = (tourDate: WebsiteBuilderTourDateModel) => {
    const newTourDates = tourDates.filter((item) => item !== tourDate);
    setValue('tourDates', newTourDates);
    setIsDeleteDialogOpen(false);
  };

  useEffect(() => {
    if (chosenTourDate) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [chosenTourDate]);

  return (
    <>
      <AddTourDateModal open={open} output={addTourDate} chosenTourDate={chosenTourDate} />
      {dateToDelete && (
        <DialogModal
          open={isDeleteDialogOpen}
          isDelete
          title="Are you sure you want to delete this gig?"
          output={(value) => {
            if (value) {
              removeTourDate(dateToDelete as WebsiteBuilderTourDateModel);
            }
            setIsDeleteDialogOpen(false);
            setDateToDelete(undefined);
          }}
        />
      )}
      <h3>{t('WEBSITE-BUILDER.TOUR-DATES')}</h3>
      <p className="text-faded mb48 mb20-lg-down mt10">{t('WEBSITE-BUILDER.TOUR-DATES-DESCRIPTION')}</p>
      <div className="card mb20 m-auto mb300">
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w40p w100p-lg-down text-left">
            <h3>{t('WEBSITE-BUILDER.ADD-TOUR-DATES')}</h3>
            <p className="text-faded mt8">{t('WEBSITE-BUILDER.ADD-TOUR-DATES-DESCRIPTION')}</p>
          </div>
          <div className="flex-w60p w100p-lg-down text-left">
            {tourDates?.map((tourDate, index) => (
              <ButtonComponent isCustom className="w100p p0" onClick={() => setChosenTourDate(tourDate)} key={index}>
                <Card innerInner className="d-flex p10 gap8 mb8 align-items-center w100p">
                  <div className="text-center">
                    <div className="stepper-container">
                      <div
                        className={`step-container`}
                        style={{ '--animation-number': `${index}` } as React.CSSProperties}
                      >
                        <div className={`step active`}>
                          <div className="step-inner">
                            {format(parseISO(new Date(tourDate.date as string).toISOString()), 'd')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="mt-auto mb-auto small">
                      {format(parseISO(new Date(tourDate.date as string).toISOString()), 'MMM, yy')}
                    </p>
                  </div>
                  <div className="pl16 text-left">
                    <p>{tourDate.venue}</p>
                    <p className="small text-faded">
                      {tourDate.city}, {tourDate.country}
                    </p>
                  </div>
                  <div className="ml-auto min-w90 d-flex jc-end">
                    {tourDate.ticketLink && (
                      <Tooltip title={`Ticket link: ${tourDate.ticketLink}`} placement="top">
                        <Button
                          className="m0 icon-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open(tourDate.ticketLink, '_blank');
                          }}
                        >
                          <CustomIcons className="pt6" name="link" />
                        </Button>
                      </Tooltip>
                    )}
                    <Button
                      className="icon-btn m0 ml8"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDateToDelete(tourDate);
                        setIsDeleteDialogOpen(true);
                      }}
                    >
                      <CustomIcons className="material-symbols-outlined text-error fw-light" name="delete" />
                    </Button>
                  </div>
                </Card>
              </ButtonComponent>
            ))}
            <div className="w100p d-flex">
              <Button
                className="btn-white m0 ml-auto"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <Icon className="material-symbols-outlined">add</Icon>
                {t('WEBSITE-BUILDER.ADD-DATE')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TourDatesPage;
