import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon } from '@mui/material';

import ErrorBoxCard from '@/components/cards/ErrorBoxCard';
import { MetaErrorValidationErrorModel } from '@/models/Meta';
import {
  WebsiteBuilderCommunityModel,
  WebsiteBuilderModel,
  WebsiteBuilderPlatformMusicLinksModel,
  WebsiteBuilderPlatformSocialLinksModel,
  WebsiteBuilderSpotlightModel,
  WebsiteBuilderTrackingModel,
  WebsiteBuilderVideoModel,
} from '@/models/WebsiteBuilder';

const WebsiteSummaryPage = ({
  goToStep,
  errors,
}: {
  errors?: MetaErrorValidationErrorModel[];
  goToStep: (step: number) => void;
}) => {
  const { t } = useTranslation();

  const { getValues } = useFormContext<WebsiteBuilderModel>();

  const mailingListFormatter = (links?: WebsiteBuilderCommunityModel) => {
    if (links?.collectEmails && links?.collectPhoneNumbers) {
      return 'Phone and email collection';
    }

    if (links?.collectEmails) {
      return 'Email collection';
    }

    if (links?.collectPhoneNumbers) {
      return 'Phone number collection';
    }

    return 'No Phone and no email collection';
  };

  const youtubeVideoFormatter = (links?: WebsiteBuilderVideoModel) => {
    return links?.url ? 'Video linked' : 'No video linked';
  };

  const formatLinks = <T,>({
    links,
    services,
    emptyMessage,
  }: {
    links?: T;
    services: { key: string; name: string }[];
    emptyMessage: string;
  }) => {
    if (!links) {
      return emptyMessage;
    }

    const activeLinks = services.filter(({ key }) => links[key as keyof T]).map(({ name }) => name);
    return activeLinks.length > 0 ? activeLinks.join(', ') : emptyMessage;
  };

  const streamingLinksFormatter = useCallback((links?: WebsiteBuilderSpotlightModel): string => {
    const services: { key: keyof WebsiteBuilderSpotlightModel; name: string }[] = [
      { key: 'spotifyLink', name: 'Spotify' },
      { key: 'appleMusicLink', name: 'Apple Music' },
      { key: 'itunesLink', name: 'iTunes' },
      { key: 'youTubeMusicLink', name: 'YouTube Music' },
      { key: 'amazonMusicLink', name: 'Amazon Music' },
      { key: 'deezerLink', name: 'Deezer' },
    ];

    return formatLinks<WebsiteBuilderSpotlightModel>({ links, services, emptyMessage: 'No streaming links' });
  }, []);

  const communityLinksFormatter = useCallback((links?: WebsiteBuilderCommunityModel) => {
    const services: { key: keyof WebsiteBuilderCommunityModel; name: string }[] = [
      { key: 'discordChannel', name: 'Discord' },
      { key: 'instagramBroadcast', name: 'Instagram' },
      { key: 'whatsAppGroup', name: 'WhatsApp' },
    ];

    return formatLinks<WebsiteBuilderCommunityModel>({ links, services, emptyMessage: 'No community links' });
  }, []);

  const socialAccountsFormatter = useCallback((links?: WebsiteBuilderPlatformSocialLinksModel) => {
    const services: { key: keyof WebsiteBuilderPlatformSocialLinksModel; name: string }[] = [
      { key: 'facebook', name: 'Facebook' },
      { key: 'instagram', name: 'Instagram' },
      { key: 'tikTok', name: 'TikTok' },
      { key: 'twitter', name: 'Twitter' },
      { key: 'snapchat', name: 'Snapchat' },
    ];

    return formatLinks<WebsiteBuilderPlatformSocialLinksModel>({
      links,
      services,
      emptyMessage: 'No social account links',
    });
  }, []);

  const musicAccountsFormatter = useCallback((links?: WebsiteBuilderPlatformMusicLinksModel) => {
    const services: { key: keyof WebsiteBuilderPlatformMusicLinksModel; name: string }[] = [
      { key: 'spotify', name: 'Spotify' },
      { key: 'youTube', name: 'YouTube' },
      { key: 'appleMusic', name: 'Apple Music' },
      { key: 'boomplay', name: 'Boomplay' },
      { key: 'deezer', name: 'Deezer' },
      { key: 'amazonMusic', name: 'Amazon Music' },
      { key: 'audiomack', name: 'Audiomack' },
    ];

    return formatLinks<WebsiteBuilderPlatformMusicLinksModel>({
      links,
      services,
      emptyMessage: 'No music account links',
    });
  }, []);

  const trackingPixelsFormatter = useCallback((links?: WebsiteBuilderTrackingModel) => {
    const services: { key: keyof WebsiteBuilderTrackingModel; name: string }[] = [
      { key: 'metaId', name: 'Meta' },
      { key: 'tikTokId', name: 'TikTok' },
    ];

    return formatLinks<WebsiteBuilderTrackingModel>({
      links,
      services,
      emptyMessage: 'No tracking pixels links',
    });
  }, []);

  const summaryData = useMemo(() => {
    const formValues = getValues();
    return [
      {
        name: 'WEBSITE-BUILDER.ARTIST-NAME',
        value: formValues.artistName,
        relatedPageStep: 1,
      },
      {
        name: 'WEBSITE-BUILDER.STREAMING-LINKS',
        value: streamingLinksFormatter(formValues.spotlightRelease),
        relatedPageStep: 3,
      },
      {
        name: 'WEBSITE-BUILDER.MAILING-LIST',
        value: mailingListFormatter(formValues.community),
        relatedPageStep: 2,
      },
      {
        name: 'WEBSITE-BUILDER.COMMUNITY-LINKS',
        value: communityLinksFormatter(formValues.community),
        relatedPageStep: 2,
      },
      {
        name: 'WEBSITE-BUILDER.YOUTUBE-VIDEO',
        value: youtubeVideoFormatter(formValues.video),
        relatedPageStep: 4,
      },
      {
        name: 'WEBSITE-BUILDER.SOCIAL-ACCOUNTS',
        value: socialAccountsFormatter(formValues.platformLinks?.socialLinks),
        relatedPageStep: 5,
      },
      {
        name: 'WEBSITE-BUILDER.MUSIC-ACCOUNTS',
        value: musicAccountsFormatter(formValues.platformLinks?.musicLinks),
        relatedPageStep: 5,
      },
      {
        name: 'WEBSITE-BUILDER.TRACKING-PIXELS',
        value: trackingPixelsFormatter(formValues.trackingPixels),
        relatedPageStep: 6,
      },
      {
        name: 'WEBSITE-BUILDER.YOUR-ROUTE',
        value: formValues.route,
        relatedPageStep: 7,
      },
    ];
  }, [
    communityLinksFormatter,
    getValues,
    musicAccountsFormatter,
    socialAccountsFormatter,
    streamingLinksFormatter,
    trackingPixelsFormatter,
  ]);

  return (
    <>
      <h3>{t('PLAYLISTING.SUMMARY')}</h3>
      <p className="text-faded mb48 mb20-lg-down mt10">{t('WEBSITE-BUILDER.SUMMARY-DESCRIPTION')}</p>
      <div className="card mb20 m-auto mb300">
        {summaryData.length > 0 &&
          summaryData?.map((item) => (
            <div key={item.name} className="d-flex jc-space-between mb20">
              <p className="text-left mr16">{t(item.name)}</p>
              <p className="text-right text-faded capitalize ml-auto pr10">{item.value}</p>
              <Icon
                className="text-faded cursor-pointer"
                onClick={() => {
                  goToStep(item.relatedPageStep);
                }}
              >
                chevron_right
              </Icon>
            </div>
          ))}
        {errors && (
          <div className="mt20">
            <ErrorBoxCard errors={errors} />
          </div>
        )}
      </div>
    </>
  );
};

export default WebsiteSummaryPage;
