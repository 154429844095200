import React from 'react';
import Lottie from 'react-lottie-player';

import { Icon } from '@mui/material';

import Loading from '@/components/utility/Loading';
import { DesktopScreen } from '@/components/utility/screen-prototypes/DesktopScreen';
import { MobileScreen } from '@/components/utility/screen-prototypes/MobileScreen';
import { TabletScreen } from '@/components/utility/screen-prototypes/TabletScreen';
import { WebsiteBuilderBackgrounds } from '@/constants/WebsiteBuilderBackgrounds';
import useArtist from '@/hooks/artist/useArtist';
import useWalkthrough from '@/hooks/website-builder/useWalkthrough';

import FanHubPreviewContents from './FanHubPreviewContents';

const WebsiteWalkthrough = () => {
  const { artist } = useArtist();
  const { walkthrough, walkthroughIsLoading } = useWalkthrough({ walkthrough: 'desktop' });

  const primaryLinks = [
    { link: 'https://www.instagram.com', customText: 'Check out our new track' },
    { link: 'https://www.facebook.com', customText: 'Come and see us on tour' },
    { link: 'https://www.twitter.com', customText: 'Sign up and stay in the loop' },
  ];

  const loremIpsum = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi.`;

  return (
    <div className="max-w750 ml-auto mr-auto mt50">
      {walkthroughIsLoading && (
        <div className="mt-auto mb-auto h400 d-flex jc-center" style={{ alignItems: 'center' }}>
          <Loading />
        </div>
      )}
      {walkthrough && <Lottie animationData={walkthrough} play loop />}
      {!walkthrough && !walkthroughIsLoading && (
        <div className="pos-rel d-flex preview-group-container">
          <div className="mobile-group">
            <MobileScreen>
              <FanHubPreviewContents
                artistName={'Drake'}
                image="https://i.scdn.co/image/ab6761610000e5eb4293385d324db8558179afd9"
                primaryLinks={primaryLinks}
                bio={loremIpsum}
                chosenBackground={WebsiteBuilderBackgrounds.images[0]}
              ></FanHubPreviewContents>
            </MobileScreen>
          </div>
          <div className="desktop-group">
            <DesktopScreen>
              <FanHubPreviewContents
                artistName={artist?.details?.name || 'Adele'}
                image={
                  artist?.details?.profileImageUrl || 'https://i.scdn.co/image/ab6761610000e5eb68f6e5892075d7f22615bd17'
                }
                primaryLinks={primaryLinks}
                bio={loremIpsum}
                chosenBackground={WebsiteBuilderBackgrounds.images[3]}
              ></FanHubPreviewContents>
            </DesktopScreen>
          </div>
          <div className="tablet-group">
            <TabletScreen>
              <FanHubPreviewContents
                artistName={'Adele'}
                image={'https://i.scdn.co/image/ab6761610000e5eb68f6e5892075d7f22615bd17'}
                primaryLinks={primaryLinks}
                bio={loremIpsum}
                chosenBackground={WebsiteBuilderBackgrounds.images[5]}
              ></FanHubPreviewContents>
            </TabletScreen>
          </div>
        </div>
      )}
      <div className="card d-flex">
        <Icon className="mt-auto mb-auto">public</Icon>
        <p className="text-left pl8">
          We've combined the power of AI with our music and design expertise so you can put together a website in
          minutes.
        </p>
      </div>
    </div>
  );
};

export default WebsiteWalkthrough;
