import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon } from '@mui/material';

import CustomIcons from '@/components/utility/microcomponents/CustomIcons';
import DialogModal from '@/components/utility/modals/DialogModal';
import { WebsiteBuilderModel } from '@/models/WebsiteBuilder';

const pixelInfo = {
  whatIsAPixel: {
    title: 'WEBSITE-BUILDER.WHAT-IS-A-PIXEL',
    content: 'WEBSITE-BUILDER.WHAT-IS-A-PIXEL-DESCRIPTION',
  },
  whyIsThisImportant: {
    title: 'WEBSITE-BUILDER.WHY-THIS-IS-IMPORTANT',
    content: 'WEBSITE-BUILDER.WHY-THIS-IS-IMPORTANT-DESCRIPTION',
  },
  metaInfo: {
    title: 'WEBSITE-BUILDER.META-PIXELS',
    content: 'WEBSITE-BUILDER.META-PIXELS-DESCRIPTION',
  },
  tikTokInfo: {
    title: 'WEBSITE-BUILDER.TIKTOK-PIXELS',
    content: 'WEBSITE-BUILDER.TIKTOK-PIXELS-DESCRIPTION',
  },
};

const TrackingPixelsPage = () => {
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [chosenPixelInfo, setChosenPixelInfo] = useState<{ title: string; content: string }>();
  const [isLink, setIsLink] = useState<{ title: string; link: string }>();

  const { setValue, watch } = useFormContext<WebsiteBuilderModel>();
  const formMetaId = watch('trackingPixels.metaId');
  const formTikTokId = watch('trackingPixels.tikTokId');

  const handleDialogOutput = () => {
    setDialogOpen(false);
    setChosenPixelInfo(undefined);
  };

  return (
    <>
      {chosenPixelInfo && (
        <DialogModal
          open={dialogOpen}
          isInfoDialog={true}
          title={chosenPixelInfo?.title}
          content={chosenPixelInfo?.content}
          isLink={isLink}
          output={() => handleDialogOutput()}
        />
      )}
      <h3>{t('WEBSITE-BUILDER.AUDIENCE-BUILDING')}</h3>
      <p className="text-faded mb48 mb20-lg-down mt10">
        <span>{t('WEBSITE-BUILDER.AUDIENCE-BUILDING-DESCRIPTION-1')}</span>{' '}
        <span
          className="text-white cursor-pointer underline"
          onClick={() => {
            setChosenPixelInfo(pixelInfo.whatIsAPixel);
            setDialogOpen(true);
          }}
        >
          {t('WEBSITE-BUILDER.AUDIENCE-BUILDING-DESCRIPTION-2')}
        </span>{' '}
        <span>{t('WEBSITE-BUILDER.AUDIENCE-BUILDING-DESCRIPTION-3')}</span>{' '}
        <span
          className="text-white cursor-pointer underline"
          onClick={() => {
            setChosenPixelInfo(pixelInfo.whyIsThisImportant);
            setDialogOpen(true);
          }}
        >
          {t('WEBSITE-BUILDER.AUDIENCE-BUILDING-DESCRIPTION-4')}
        </span>
      </p>
      <div className="card mb20 m-auto mb300">
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w40p w100p-lg-down text-left">
            <h3>{t('WEBSITE-BUILDER.PIXEL-ID')}</h3>
            <p className="text-faded mt8 small"></p>
          </div>
          <div className="flex-w60p w100p-lg-down text-left">
            <div className="d-flex jc-space-between">
              <h4 className="mb8">{t('WEBSITE-BUILDER.META-PIXEL-ID')}</h4>
              <Icon
                className="material-symbols-outlined cursor-pointer"
                onClick={() => {
                  setIsLink({
                    title: 'WEBSITE-BUILDER.OUR-BLOG',
                    link: 'https://www.unhurd.co.uk/blog/tracking-pixel-for-artists',
                  });
                  setChosenPixelInfo(pixelInfo.metaInfo);
                  setDialogOpen(true);
                }}
              >
                help
              </Icon>
            </div>
            <div className="pos-rel">
              <CustomIcons className="input-prefix-icon" name="link" />
              <input
                className="has-prefix-icon"
                placeholder={t('WEBSITE-BUILDER.META-PIXEL-ID')}
                value={formMetaId ?? ''}
                onChange={(event) =>
                  event.target.value === ''
                    ? setValue('trackingPixels.metaId', undefined)
                    : setValue('trackingPixels.metaId', event.target.value)
                }
              />
            </div>
            <div className="d-flex jc-space-between">
              <h4 className="mb8 mt20">{t('WEBSITE-BUILDER.TIKTOK-PIXEL-ID')}</h4>
              <Icon
                className="material-symbols-outlined cursor-pointer mt20"
                onClick={() => {
                  setIsLink({
                    title: 'WEBSITE-BUILDER.OUR-BLOG',
                    link: 'https://www.unhurd.co.uk/blog/tracking-pixel-for-artists',
                  });
                  setChosenPixelInfo(pixelInfo.tikTokInfo);
                  setDialogOpen(true);
                }}
              >
                help
              </Icon>
            </div>

            <div className="pos-rel">
              <CustomIcons className="input-prefix-icon" name="link" />
              <input
                className="has-prefix-icon"
                placeholder={t('WEBSITE-BUILDER.TIKTOK-PIXEL-ID')}
                value={formTikTokId ?? ''}
                onChange={(event) =>
                  event.target.value === ''
                    ? setValue('trackingPixels.tikTokId', undefined)
                    : setValue('trackingPixels.tikTokId', event.target.value)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrackingPixelsPage;
