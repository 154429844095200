import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, CircularProgress } from '@mui/material';

import Loading from '@/components/utility/Loading';
import CustomIcons from '@/components/utility/microcomponents/CustomIcons';
import useArtist from '@/hooks/artist/useArtist';
import useValidateUniqueArtist from '@/hooks/website-builder/useValidateUniqueArtist';
import { WebsiteBuilderModel, WebsiteBuilderPrimaryLinksModel } from '@/models/WebsiteBuilder';
import WebsiteBuilderAPI from '@/network/WebsiteBuilderAPI';
import { handleApiError } from '@/utility/api';
import { isValidUrl } from '@/utility/validations';

const WebsiteBuilderLandingPage = ({
  isEditMode,
  canStep,
}: {
  isEditMode: boolean;
  canStep: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  const { artist } = useArtist();

  const [isEnhancingBioLoading, setIsEnhancingBioLoading] = useState<boolean>(false);

  const [linkOne, setLinkOne] = useState<WebsiteBuilderPrimaryLinksModel>();
  const [linkTwo, setLinkTwo] = useState<WebsiteBuilderPrimaryLinksModel>();
  const [linkThree, setLinkThree] = useState<WebsiteBuilderPrimaryLinksModel>();

  const { setValue, watch } = useFormContext<WebsiteBuilderModel>();
  const formPrimaryLinks = watch('primaryLinks');
  const formBio = watch('bio');
  const formArtistName = watch('artistName');

  const { isValidUniqueArtist, validateUniqueArtistError, validateUniqueArtistIsLoading } = useValidateUniqueArtist({
    bypassCheck: isEditMode,
  });

  useEffect(() => {
    if (linkOne || linkTwo || linkThree) return;
    if (!formPrimaryLinks || !formPrimaryLinks.length) return;

    if (formPrimaryLinks[0]) {
      setLinkOne({ link: formPrimaryLinks[0].link, customText: formPrimaryLinks[0].customText });
    }
    if (formPrimaryLinks[1]) {
      setLinkTwo({ link: formPrimaryLinks[1].link, customText: formPrimaryLinks[1].customText });
    }
    if (formPrimaryLinks[2]) {
      setLinkThree({ link: formPrimaryLinks[2].link, customText: formPrimaryLinks[2].customText });
    }
  }, [formPrimaryLinks, linkOne, linkThree, linkTwo]);

  const enhanceBio = useCallback(async () => {
    setIsEnhancingBioLoading(true);
    try {
      if (!artist || !formBio || formBio.length < 30) return;

      const response = await WebsiteBuilderAPI.enhanceBio({ artistId: artist.id, bio: formBio });
      setValue('bio', response.data[0]);
    } catch (error: unknown) {
      handleApiError({ error });
    } finally {
      setIsEnhancingBioLoading(false);
    }
  }, [artist, formBio, setValue]);

  useEffect(() => {
    const data = [];
    if (linkOne && linkOne.link && linkOne.customText) {
      data.push(linkOne);
    }

    if (linkTwo && linkTwo.link && linkTwo.customText) {
      data.push(linkTwo);
    }

    if (linkThree && linkThree.link && linkThree.customText) {
      data.push(linkThree);
    }

    setValue('primaryLinks', data);
  }, [linkOne, linkThree, linkTwo, setValue]);

  useEffect(() => {
    canStep(!!(formArtistName.length && (formBio === '' || (formBio && formBio.length >= 30))));
  }, [canStep, formArtistName.length, formBio]);

  if (validateUniqueArtistIsLoading) {
    return (
      <>
        <h3>{t('WEBSITE-BUILDER.LANDING-PAGE')}</h3>
        <p className="text-faded mb48 mb20-lg-down mt10">{t('WEBSITE-BUILDER.LANDING-PAGE-DESCRIPTION')}</p>
        <div className="centered-loading mt48">
          <Loading />
        </div>
      </>
    );
  }

  if (validateUniqueArtistError) {
    return (
      <>
        <h3>{t('WEBSITE-BUILDER.LANDING-PAGE')}</h3>
        <p className="text-faded mb48 mb20-lg-down mt10">{t('WEBSITE-BUILDER.LANDING-PAGE-DESCRIPTION')}</p>
        <div>
          <h4 className="text-error">A problem occured, please try again</h4>
        </div>
      </>
    );
  }

  if (!isValidUniqueArtist && !isEditMode) {
    return (
      <>
        <h3>{t('WEBSITE-BUILDER.LANDING-PAGE')}</h3>
        <p className="text-faded mb48 mb20-lg-down mt10">{t('WEBSITE-BUILDER.LANDING-PAGE-DESCRIPTION')}</p>
        <div>
          <h4 className="text-error">It looks like this artist already has a website with us</h4>
        </div>
      </>
    );
  }

  return (
    <>
      <h3>{t('WEBSITE-BUILDER.LANDING-PAGE')}</h3>
      <p className="text-faded mb48 mb20-lg-down mt10">{t('WEBSITE-BUILDER.LANDING-PAGE-DESCRIPTION')}</p>
      <div className="card mb20 m-auto mb300">
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w40p w100p-lg-down text-left">
            <h3>{t('WEBSITE-BUILDER.ABOUT-YOU')}</h3>
            <p className="text-faded mt8">{t('WEBSITE-BUILDER.ABOUT-YOU-DESCRIPTION')}</p>
          </div>
          <div className="flex-w60p w100p-lg-down text-left">
            <div className="card-inner">
              <h4 className="mb8">{t('WEBSITE-BUILDER.ARTIST-NAME')}</h4>
              <div className="pos-rel">
                <input
                  placeholder={t('WEBSITE-BUILDER.ARTIST-NAME')}
                  value={formArtistName}
                  maxLength={35}
                  onChange={(event) => setValue('artistName', event.target.value)}
                />
                <div className="input-counter">{formArtistName.length}/35</div>
              </div>
            </div>
            <div className="card-inner mt20">
              <h4 className="mb8">{t('WEBSITE-BUILDER.INCLUDE-A-SHORT-BIO')}</h4>
              <div className="pos-rel">
                <textarea
                  className="pb50"
                  disabled={isEnhancingBioLoading}
                  value={formBio}
                  maxLength={500}
                  placeholder={t('WEBSITE-BUILDER.BIO-PLACEHOLDER')}
                  onChange={(event) => setValue('bio', event.target.value)}
                />
                <div className="textarea-counter">(minimum 30 characters) {formBio?.length || 0}/500</div>
                <Button
                  disabled={isEnhancingBioLoading || !formBio || formBio.length < 30}
                  className="border-btn-grey m0 mt8 enhance-bio-btn"
                  onClick={enhanceBio}
                >
                  {isEnhancingBioLoading ? <CircularProgress size={16} /> : 'Enhance with AI'}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex form-divider mt10 mb20">
          <div className="line"></div>
        </div>
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w40p w100p-lg-down text-left">
            <h3>{t('WEBSITE-BUILDER.YOUR-LINKS')}</h3>
            <p className="text-faded mt8">{t('WEBSITE-BUILDER.YOUR-LINKS-DESCRIPTION')}</p>
          </div>
          <div className="flex-w60p w100p-lg-down text-left">
            <div className="card-inner">
              <h4 className="mb8">{t('WEBSITE-BUILDER.FIRST-LINK')}</h4>
              <div className="pos-rel">
                <CustomIcons className="input-prefix-icon" name="link" />
                <input
                  className={`has-prefix-icon ${linkOne?.link && !isValidUrl(linkOne?.link) ? 'error-outline' : ''}`}
                  placeholder={t('WEBSITE-BUILDER.PASTE-YOUR-LINK-HERE')}
                  value={linkOne?.link}
                  onChange={(event) => setLinkOne({ ...linkOne, link: event.target.value })}
                />
              </div>
              <div className="pos-rel">
                <input
                  className="mt8"
                  maxLength={35}
                  placeholder={t('WEBSITE-BUILDER.SIGN-UP-TO-MY-MAILING-LIST')}
                  value={linkOne?.customText}
                  onChange={(event) => setLinkOne({ ...linkOne, customText: event.target.value })}
                />
                <div className="input-counter">{linkOne?.customText?.length ?? 0}/35</div>
              </div>
            </div>
            <div className="card-inner mt20">
              <h4 className="mb8">{t('WEBSITE-BUILDER.SECOND-LINK')}</h4>
              <div className="pos-rel">
                <CustomIcons className="input-prefix-icon" name="link" />
                <input
                  className={`has-prefix-icon ${linkTwo?.link && !isValidUrl(linkTwo?.link) ? 'error-outline' : ''}`}
                  placeholder={t('WEBSITE-BUILDER.PASTE-YOUR-LINK-HERE')}
                  value={linkTwo?.link}
                  onChange={(event) => setLinkTwo({ ...linkTwo, link: event.target.value })}
                />
              </div>
              <div className="pos-rel">
                <input
                  className="mt8"
                  maxLength={35}
                  placeholder={t('WEBSITE-BUILDER.PRE-SAVE-MY-SONG')}
                  value={linkTwo?.customText}
                  onChange={(event) => setLinkTwo({ ...linkTwo, customText: event.target.value })}
                />
                <div className="input-counter">{linkTwo?.customText?.length ?? 0}/35</div>
              </div>
            </div>
            <div className="card-inner mt20">
              <h4 className="mb8">{t('WEBSITE-BUILDER.THIRD-LINK')}</h4>
              <div className="pos-rel">
                <CustomIcons className="input-prefix-icon" name="link" />
                <input
                  className={`has-prefix-icon ${linkThree?.link && !isValidUrl(linkThree?.link) ? 'error-outline' : ''}`}
                  placeholder={t('WEBSITE-BUILDER.PASTE-YOUR-LINK-HERE')}
                  value={linkThree?.link}
                  onChange={(event) => setLinkThree({ ...linkThree, link: event.target.value })}
                />
              </div>
              <div className="pos-rel">
                <input
                  className="mt8"
                  maxLength={35}
                  placeholder={t('WEBSITE-BUILDER.CHECK-OUT-MY-TOUR-DATES')}
                  value={linkThree?.customText}
                  onChange={(event) => setLinkThree({ ...linkThree, customText: event.target.value })}
                />
                <div className="input-counter">{linkThree?.customText?.length ?? 0}/35</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebsiteBuilderLandingPage;
